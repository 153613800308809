import { Box, Button, } from "@mui/material";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from "react";
import { landingPage_styles } from "./landing_page";
import pannel_one from "./landing_assets/Frame 167.svg"
import pannel_two from "./landing_assets/Frame 173.svg"
import pannel_three from "./landing_assets/Frame 173.svg"
import cardio from "./landing_assets/Cardiogram.svg"
import money from "./landing_assets/Money bag.svg"
import connection from "./landing_assets/Capa_1 (1).svg"
import champ from "./landing_assets/Capa_1 (2).svg"
import idea from "./landing_assets/Capa_1.svg"
import flower from "./landing_assets/Flower-animation_2.gif"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useMediaQuery } from "react-responsive";
import vision from "./landing_assets/vission.svg"
import mission from "./landing_assets/mission.svg"
import { useNavigate } from "react-router-dom";
import {motion} from "framer-motion"
import { TranslateText } from "../utils/googletranslator";
import { useSelector } from "react-redux";

const tabIndices = {
    'WHO WE ARE': 0,
    'WHAT WE STAND FOR': 1,
    'WHAT WE DO': 2,
  };
  
  const getTabStyle = (currentValue, previousValue) => {
    // console.log(currentValue,previousValue)
    const currentIdx = tabIndices[currentValue];
    const previousIdx = tabIndices[previousValue];
    // console.log(currentIdx,previousIdx)
  
    if (currentIdx > previousIdx) {
        // console.log('tabActiveLTR')

      return landingPage_styles.whoWeAre.tabActiveLTR;
    } else {
        // console.log('tabActiveRTL')
      return landingPage_styles.whoWeAre.tabActiveRTL;
    }
  };
export function WhoWeAre() {
    const navigate = useNavigate()
    const [previousValue, setPreviousValue] = useState('WHO WE ARE');
    const [value, setValue] = useState('WHO WE ARE');
    const language=useSelector(state=>state.user.language)
     
    const handleChange = (event, newValue) => {
        setPreviousValue(value);
        setValue(newValue);
    };
    const isLaptop = useMediaQuery({ minWidth: 900 });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (<Box sx={{...landingPage_styles.whoWeAre.page_prop,...landingPage_styles.positionAnimation,bgcolor:isMobile?"grey.50":"white"}}>
        {isLaptop && <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>

            <Box sx={{ maxWidth: "818px", width: "100%", background: "#F8F9FF", borderRadius: "29px", height: "66px", padding: "8px" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={landingPage_styles.whoWeAre.tabs}
                    textColor="white"
                    indicatorColor="transparent"

                >
                    <Tab sx={{ ...(value == "WHO WE ARE" ? getTabStyle('WHO WE ARE', previousValue) : landingPage_styles.whoWeAre.tabInActive)}} value="WHO WE ARE" label={<TranslateText>Who We Are</TranslateText>} />
                    <Tab sx={{ ...(value == "WHAT WE STAND FOR" ? getTabStyle('WHAT WE STAND FOR', previousValue): landingPage_styles.whoWeAre.tabInActive) }} value="WHAT WE STAND FOR" label={<TranslateText>WHAT WE STAND FOR</TranslateText>} />
                    <Tab sx={{ ...(value == "WHAT WE DO" ? getTabStyle('WHAT WE DO', previousValue): landingPage_styles.whoWeAre.tabInActive)}} value="WHAT WE DO" label={<TranslateText> WHAT WE DO</TranslateText>} />
                </Tabs>
            </Box>

        </Box>}
        <Box sx={{ width: "100%" }}>
            {(value == "WHO WE ARE" || !isLaptop) && <Box sx={{display:"flex",flexDirection:"column",gap:"30px"}}>
                <Box sx={landingPage_styles.whoWeAre.contentBox1}>
                <Box sx={landingPage_styles.whoWeAre.textSection}>
                    <Box sx={{display:"flex",flexDirection:"column",gap:"8px"}}>
                    <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={{ color: "primary.light", ...landingPage_styles.whoWeAre.heading }}>WHO WE ARE</TranslateText></motion.div>
                    <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.headingBold}>Building A Social Enterprise</TranslateText></motion.div>
                    </Box>
                    <Box sx={{display:"flex",flexDirection:{xs:"column-reverse",md:"row"},width:"100%"}}>
                        <Box sx={{width:{xs:"100%",md:"35%",display:"flex",flexDirection:"column",gap:"30px"}}}> 

                        <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.content}>May harmony be experienced in everyone's heart through love and kindness to all including nature.</TranslateText></motion.div>
                        <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.content}> MAYI Group is leading wellness for more than 20 years promoting health and well-being. MAYI was started in 2018 and aims to provide the best of nature in its purest form. MAYI provides the opportunity for everyone to experience the wonders of these high-quality natural products while empowering them to achieve their personal goals through the meaningful sharing of our in-depth knowledge and philosophy that promotes wellbeing.</TranslateText></motion.div>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center",width:{xs:"100%",md:"65%"} }}>
                        <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Box component="img" sx={landingPage_styles.whoWeAre.image1} src={pannel_one}></Box></motion.div>
                        </Box>
                    </Box>

                </Box>
                </Box>
                <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}>
                <Box sx={{display:"flex",gap:"30px",width:"100%",overflow:"auto",'::-webkit-scrollbar': {display: 'none'} }}>
                {/* <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}} > */}
                    <Box sx={{width:isMobile?"300px":"50%",background:"#F8F9FF",borderRadius:"8px",display:"flex",flexDirection:"column",gap:"30px",justifyContent:"start",alignItems:"center",padding:"20px"}}>
                        <Box component="img" src={vision}></Box>
                        <Box sx={{maxWidth:isMobile?"auto":"475px",width:isMobile?"270px":"100%"}}>
                            <TranslateText sx={{fontSize:"23px",fontWeight:"700",textAlign:"center"}}>Our Vision: </TranslateText>
                            <TranslateText sx={{...landingPage_styles.whoWeAre.content,textAlign:"center"}}>MAYI's vision is to create a world that practices natural lifestyle.</TranslateText>
                        </Box> 
                    </Box>
                    {/* <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}} > */}
                    <Box sx={{width:isMobile?"300px":"50%",background:"#F8F9FF",borderRadius:"8px",display:"flex",flexDirection:"column",gap:"30px",justifyContent:"start",alignItems:"center",padding:"20px"}}>
                        <Box component="img" src={mission}></Box>
                        <Box sx={{maxWidth:isMobile?"auto":"475px",width:isMobile?"270px":"100%"}}>
                            <TranslateText sx={{fontSize:"23px",fontWeight:"700",textAlign:"center"}}>Our Mission:</TranslateText>
                            <TranslateText sx={{...landingPage_styles.whoWeAre.content,textAlign:"center"}}>Is to educate humanity to reach its full health potential through a sustainable social enterprise structure.</TranslateText>
                        </Box> 
                    </Box>
                </Box>
                </motion.div>

                
            </Box>}
            {(value == "WHAT WE STAND FOR" || !isLaptop) && <Box sx={landingPage_styles.whoWeAre.contentBox2}>
                <Box sx={landingPage_styles.whoWeAre.textSection}>
                <Box sx={{display:"flex",flexDirection:"column",gap:"8px"}}>
                     <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={{ color: "#00E471", ...landingPage_styles.whoWeAre.heading }}>WHAT WE STAND FOR</TranslateText></motion.div>
                     <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.headingBold}>Our MAYI - IQ Culture</TranslateText></motion.div>
                     </Box>
                    <Box sx={{ display: "flex", gap: {xs:"12px",md:"30px"}, flexDirection: "column", width: "100%" }}>
                        <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.subContent}>Health, Wealth, Wisdom, Relationship & Self</TranslateText></motion.div>
                        <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={{textAlign:{xs:"center",md:"left"}}}>At MAYI, our culture revolves around fostering holistic well-being and personal growth.</TranslateText></motion.div>
                    </Box>
                    <Box sx={{display:"flex",flexDirection:{xs:"column-reverse",md:"row"},width:"100%"}}>
                        <Box sx={{width:{xs:"100%",sm:"100%",md:"50%"},display:"flex",flexDirection:{xs:"column-reverse",md:"row"}}}>
                              <Box sx={{ maxWidth: {md:"523px"}, width: "100%", display: "flex", flexDirection: "column", gap: "30px" }}>
                            <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Box sx={{ display: "flex", gap: "30px" }}>
                                <Box component="img" src={cardio}></Box>
                                <TranslateText>We prioritize HEALTH in all its dimensions—physical, mental, and emotional—by promoting natural exercises for the body and mind, including our signature Harmony On Earth exercise.</TranslateText>
                            </Box></motion.div>
                            <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Box sx={{ display: "flex", gap: "30px" }}>
                                <Box component="img" src={money}></Box>
                                <TranslateText>We believe in cultivating WEALTH sustainably, ensuring a high quality of life and overall wellbeing, as well as fostering stability through a sense of security and financial freedom.</TranslateText>
                            </Box></motion.div>
                            <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Box sx={{ display: "flex", gap: "30px" }}>
                                <Box component="img" src={idea}></Box>
                                <TranslateText>WISDOM is integral to our culture, encompassing living skills, financial management, kindness, balanced lifestyles, generosity, sharing, and honesty.</TranslateText>
                            </Box></motion.div>
                            <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Box sx={{ display: "flex", gap: "30px" }}>
                                <Box component="img" src={connection}></Box>
                                <TranslateText>We value RELATIONSHIPS with family, humanity, and nature, recognizing their importance in our journey.</TranslateText>
                            </Box></motion.div>
                            <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Box sx={{ display: "flex", gap: "30px" }}>
                                <Box component="img" src={champ}></Box>
                                <TranslateText>Ultimately, our culture encourages individuals to explore and connect with their inner selves, fostering happiness, Self-awareness, belief in one's abilities, purity, discipline, and effective time management.</TranslateText>
                            </Box></motion.div>
                            <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Box>
                                <Button onClick={()=>navigate('/culture')} sx={{ maxWidth: {md:language=='en'? "275px":"auto"}, width:"100%",  padding: "10px 24px", gap: "10px", background: "linear-gradient(45deg, #00C2FF 10%, #00E3AF 90%)", color: "black", textTransform: "none" ,...landingPage_styles.buttonAnimation}}><TranslateText sx={landingPage_styles.introduction.buttonText}>Know Your Journey </TranslateText><ArrowForwardIcon /></Button>
                            </Box></motion.div>
                        </Box>
                        </Box>
                      
                        <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: {xs:"100%",md:"100%"} }}>
                        <Box sx={landingPage_styles.whoWeAre.image2} component="img" src={flower}></Box>
                    </Box></motion.div>

                    </Box>
                   
                    
                </Box>
               
            </Box>}
            {(value == "WHAT WE DO" || !isLaptop) && <Box sx={landingPage_styles.whoWeAre.contentBox3}>
                <Box sx={landingPage_styles.whoWeAre.textSection}>
                 <Box sx={{maxWidth:{sm:"100%",md:"585px"},width:"100%",gap:"30px",display:"flex",flexDirection:"column"}}>
                <Box sx={{display:"flex",flexDirection:"column",gap:"8px"}}>
                    <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={{ color: "#F67423", ...landingPage_styles.whoWeAre.heading }}>WHAT WE DO, WITH YOU</TranslateText></motion.div>
                    <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.headingBold}>MAYI - IQ Soc. Ent. System</TranslateText></motion.div>
                 </Box>  
                    <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.subContent}>Unlock Your Earning Potential: Experience Financial Freedom as a Buyer of Our Products</TranslateText></motion.div>
                    <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.content}>When you purchase our products, you not only gain access to top-quality items but also become a lifetime member, free of charge. Through our user-friendly online portal, referring a friend automatically earns you points.</TranslateText></motion.div>
                    <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.content}>This network of buyers forms your own community, all benefiting from purchasing through MAYI IQ and our plant based Ayush products.</TranslateText></motion.div>
                    <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.subContent}>Join us in shaping a future of financial empowerment and mutual success.</TranslateText></motion.div>
                </Box>
               
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: {xs:"100%"},marginTop:{sm:"20px",md:"0px"},marginBottom:{xs:"-150px",md:0} }}>
                    <Box sx={[landingPage_styles.whoWeAre.image3,{zIndex:"0"}]} component="img" src={pannel_three}></Box>
                </Box>
            </Box>}
        </Box>


    </Box >)
}