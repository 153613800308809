import axios from "axios";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";

export const TranslateText =({children,sx,style} ) => {
const language=useSelector(state=>state.user.language)
// console.log("children text",children,sx,language)
let [translatedText,setTranslatedText]=useState("")
useEffect(()=>{
    if (language!='en'){
    console.log(children)
        let text=React.Children.toArray(children).map((child) => (typeof child === 'string' ? child : null))
        .filter(Boolean).join(' ')
        // console.log(text)
        axios.post(
            "https://translation.googleapis.com/language/translate/v2?key=AIzaSyCMcZ_oG9M6xnr5TLOopvBXw4YC0m61Uw0",
            {
              q: text,
              target: language,
              source:"en"
            },
            // {
            //   headers: {
            //     `,
            //   },
            // }
          ).then(({data})=>setTranslatedText(data.data.translations[0].translatedText)).catch(err=>console.log(err))
        
    }
   
},[language])



return <Typography sx={sx} style={style}>{language!='en'?translatedText:children}</Typography>
{/*  */}

  
};

export const TranslateSpanText =({children,style,} ) => {
    const language=useSelector(state=>state.user.language)
    // console.log("children text",children,language)
    let [translatedText,setTranslatedText]=useState("")
    
    useEffect(()=>{
        if (language!='en'){
        // console.log(children)
            let text=React.Children.toArray(children).map((child) => (typeof child === 'string' ? child : null))
            .filter(Boolean).join(' ')
            // console.log(text)
          
                // console.log(children)
           
            axios.post(
                "https://translation.googleapis.com/language/translate/v2?key=AIzaSyCMcZ_oG9M6xnr5TLOopvBXw4YC0m61Uw0",
                {
                  q: text,
                  target: 'ta',
                  source:"en"
                },
                // {
                //   headers: {
                //     `,
                //   },
                // }
              ).then(({data})=>setTranslatedText(data.data.translations[0].translatedText)).catch(err=>console.log(err))
            
        }
       
    },[language])
    
    
    
    return <span style={style}>{language!='en'?translatedText:children}</span>
    
      
    };
    
