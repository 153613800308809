import { Box, Typography } from "@mui/material";
import { landingPage_styles } from "./landing_page";
import panel1 from "./landing_assets/Frame 1.svg"
import panel2 from "./landing_assets/Frame 2.svg"
import panel3 from "./landing_assets/Frame 3.svg"
import { useMediaQuery } from "react-responsive";
import { TranslateText } from "../utils/googletranslator";

export function OurStrategy() {
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ minWidth: 900  });
    const contents = [
        {
            img:panel1 ,
            title: "Find a path to financial freedom",
            content: "Explore our platform and networking opportunities to carve out your path towards financial independence."
        },
        {
            img: panel2,
            title: "Attain well-being",
            content: "Nurture your well-being, fostering a state of flourishing intentions and holistic growth."
        },
        {
            img: panel3,
            title: "Engage in Social Initiatives",
            content: "Become part of our ever-growing social initiatives, contributing to positive change and collective impact."
        },
    ]
    return (<Box sx={{...landingPage_styles.power.page_prop,...landingPage_styles.ourStrategy,...landingPage_styles.positionAnimation,bgcolor:"grey.50"}}>
        <Box sx={{ display: "flex", gap: "36px",flexDirection:"column",width:"100%"}}>
            <TranslateText sx={landingPage_styles.whoWeAre.headingBold}>Our Strategy</TranslateText>
            <Box sx={{display:"flex",gap:"30px",justifyContent:isMobile?"start":"center",overflow:isMobile&&"auto","&::-webkit-scrollbar": {display: "none"}}}>
                {contents.map((content) => (<Box sx={{...landingPage_styles.ourStrategy.box}}>
                    <Box sx={{height:"218px",objectFit:"cover",width:isMobile?"226px":"100%"}} component="img" src={content.img}></Box>
                    <TranslateText sx={{...landingPage_styles.ourStrategy.heading,textTransform:"uppercase"}}>{content.title}</TranslateText>
                    <TranslateText sx={landingPage_styles.ourStrategy.content}>{content.content}</TranslateText>

                </Box>))}
            </Box>

        </Box>
    </Box>)
}