import { Box, Divider, Stack, Typography } from "@mui/material";
import { landingPage_styles } from "./landing_page";
import { useMediaQuery } from "react-responsive";
import { TranslateText } from "../utils/googletranslator";
import { useSelector } from "react-redux";
export function Certification() {
    let language=useSelector(state=>state.user.language)
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    return (
        <Box sx={{ width: "100%", position: "relative",zIndex:3}}>
            <Box sx={{...landingPage_styles.certificate.image, height: language=="en"?"314px":"400px",}} />
            
            <Box sx={{...landingPage_styles.certificate.overlay,height: language=="en"?"314px":"400px",}}>
            
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: {xs:"20px",md:"32px"} ,position:"absolute",top:0,zIndex:2,padding:"24px 16px"}}>
                    <Box sx={{display:"flex",gap:"8px",flexDirection:"column"}}>
                        <TranslateText sx={{ ...landingPage_styles.whoWeAre.headingBold, color: "white",textAlign:"center" }}>Our Platform, Your Business</TranslateText>
                        <TranslateText sx={{ ...landingPage_styles.whoWeAre.heading, color: "white",textAlign:"center",textTransform:"uppercase" }}>We are AYUSH Certified</TranslateText>
                    </Box>
                    <TranslateText sx={landingPage_styles.certificate.content}>MAYI's exceptional wellness products are designed to enhance specific aspects of your health. AYUSH certified, organic, and rooted in Ayurvedic traditions, they deliver the best of nature's healing power.</TranslateText>
                    <Stack sx={{...landingPage_styles.certificate.content, flexDirection:"row",gap:"8px",alignItems:"center",justifyContent:"center"}}>
                        <TranslateText sx={{fontWeight:700}}>Natural</TranslateText>
                        <Divider orientation="vertical" sx={{height:"100%",borderColor:landingPage_styles.certificate.content.color}}/>
                        <TranslateText  sx={{fontWeight:700}}>Mind - Body Wellness</TranslateText>
                        <Divider orientation="vertical" sx={{height:"100%",borderColor:landingPage_styles.certificate.content.color}}/>
                        <TranslateText  sx={{fontWeight:700}}>Vegan</TranslateText>
                        {/* <Divider orientation="vertical" sx={{width:"20px",height:"100%",borderColor:landingPage_styles.certificate.content.color}}/> */}
                    </Stack>
                </Box>
                </Box>
        </Box>

    )
}