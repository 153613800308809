import { Box, Button, Divider, InputBase, Typography, InputAdornment, IconButton, Checkbox, CircularProgress, Paper,Radio,RadioGroup,FormControlLabel,Tooltip } from "@mui/material";
import { updateEmail, updateMobile, updateFirstName, updateLastName,updateUserName, updatePassword, updateOtpCheck, updateMemberResponse, updateRole, updateId, updateProfile, updateMembership,updateWhatsapp  } from "../redux/user_reducer";
import { useDispatch, useSelector } from "react-redux";
import { updateSnackBarOpen,updateSnackBarSeverity,updateSnackBarMessage } from "../redux/snackbar";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect,useRef } from "react";
import mayi_logo from "../assets/mayi_logo.png";
import signupImage from "../assets/Frame 91-1.jpg";
import CustomAxios from '../utils/CustomAxios';
import { useMediaQuery } from 'react-responsive';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { countries as countries_json } from "../countries";
import InfoIcon from "@mui/icons-material/Info";
import background from "../assets/Background.jpg";
import MessageBar from "../modules/messageBar";
const Otpverify=()=>{
    const level= useSelector(state=>state.user.OtpVerifyPopup)
    return level
}
const MemberSelection=()=>{
    const member= useSelector(state=>state.user.member)
    console.log(member)
    return member=='member'?true:false
}

export const inputStyles = {
    inputBase: { border: "1px solid", borderColor: "grey.400", maxWidth: { xs: "350px", sm: "100%" }, width: "100%", borderRadius: "5px", height: "48px", fontSize: "14px", padding: "14px", color: "grey.600" },
    verifyLabel: { fontWeight: 600, fontSize: "16px",  textAlign: "left",lineHeight:"24px",color:"primary.light" },
    label: { marginBottom: "12px", fontWeight: 400, fontSize: "14px", color: "grey.900", textAlign:"left" },
    verificationLabel: { fontWeight: 400, fontSize: "12px", color: "grey.900", textAlign:"left",lineHeight:"16px",},
    terms: { fontWeight: 400, fontSize: "14px", whiteSpace: "pre-wrap" },
    paper: { padding: { sm: "32px", xs: "32px" }, display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column', bgcolor: "#fff", width:Otpverify? {xs:'100%',sm:"596px"}: { sm: "50%", xs: "100%" } },
    button: { height: "3rem", textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "100%", "&:hover": { bgcolor: "primary.light", color: "primary.contrastText" }, borderRadius: "5px", fontSize: "", textTransform: 'none', "&:focus": { border: "0px solid white" } },
    otpbutton: { height: "48px",textTransform: "none", fontWeight: 400, fontSize: "16px", width: "100%",maxWidth:"150px", "&:hover": { bgcolor: "primary.light", color: "primary.contrastText" }, borderRadius: "5px", lineHeight:"24px", "&:focus": { border: "0px solid white" } },
    heading: {},
    logo: { width: "100px", cursor: "pointer" },
    subHeading: { marginTop: "12px", fontWeight: "600", fontSize: "14px" },
    constent: { marginBottom: "8px", fontWeight: "400", fontSize: "14px", textAlign: "center" }
};

const SignupForm = () => {
    const dispatch = useDispatch();
    const first_name = useSelector(state => state.user.first_name);
    const last_name = useSelector(state => state.user.last_name);
    const email = useSelector(state => state.user.email);
    const password = useSelector(state => state.user.password);
    const mobile_no=useSelector(state=>state.user.mobile_no)
    const username=useSelector(state=>state.user.user_name)
    const [showPassword, setShowPassword] = useState(false);
    const [referralCode, setReferralCode] = useState("");
    const [response, setResponse] = useState("");
    const [isloading, setIsloading] = useState(false);
    const navigate = useNavigate();
    const [agree, setAgree] = useState(false);
    const [errors, setErrors] = useState({});
    const queryParameters = new URLSearchParams(window.location.search);
    const clientId = "521447616048-relr5c80sac2rudgmfub8kantovfc5pe.apps.googleusercontent.com"; // Replace with your actual client ID
    const authtoken=localStorage.getItem("authtoken")
    const member=useSelector((state)=>state.user.member)
    const [selectedCountry,setSelectedCountry]=useState(null)
    const [checked, setChecked] = useState(false);
    const [countries, setCountries] = useState([]);
    const [mobileNo, setMobileNo] = useState("");
    const tooltipRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [platform,setPlatform]=useState("web")
    const [openMember, setOpenMember] = useState(false);
    const [openNonMember, setOpenNonMember] = useState(false);
    // const mobile_no=useSelector(state=>state.user.mobile_no)
    useEffect(() => {
        if (selectedCountry?.value != "IN") {
          setChecked(true);
        } else {
          setChecked(false);
        }
        // console.log(selectedCountry);
      }, [selectedCountry]);
    useEffect(()=>{
        if (authtoken){
            navigate("/")
        }
    
    },[])
    useEffect(() => {
        setReferralCode(queryParameters.get('code'));
        if (queryParameters.get('code')){
          setPlatform('referred')
        }
        else if(queryParameters.get('signup_via')){
          localStorage.setItem('signup_via',queryParameters.get('signup_via'))
          navigate('/our_product')
          
          // setPlatform(queryParameters.get('signup_via'))
        }
        if(localStorage.getItem('signup_via')){
          // console.log(localStorage.getItem('signup_via'))
          setPlatform(localStorage.getItem('signup_via'))
        }
        
      }, []);
    const fetchCountries = async () => {
        const access_key = "3c20bc99871906ff01975e743bec3a5b";
        try {
          // const response = await axios.get("https://restcountries.com/v3.1/all/");
          const response = {"data":countries_json}
        //   console.log(response);
          return response.data.map((country) => ({
            value: country.cca2,
            label: country.name.common,
            dialCode: country.idd?.root + (country.idd?.suffixes?.[0] || ""),
            flag: country.flags?.png,
            numberLength: country.cca2 === "IN" ? 10 : 15,
          }));
        } catch (error) {
          console.error("Error fetching countries:", error);
          return [];
        }
      };
    
    useEffect(() => {
        const initializeCountries = async () => {
          const countryOptions = await fetchCountries();
          setCountries(countryOptions);
    
          // Set India as the default country
          const india = countryOptions.find((country) => country.value === "IN");
          setSelectedCountry(india);
          setMobileNo(india ? "" : "");
        };
    
        initializeCountries();
      }, []);
    const handleSummit = () => {
        const formData = {
            email: email,
            first_name: first_name,
            last_name: last_name,
            password: password,
            referralCode: referralCode,
            member:member,
            username:username,
            mobile_no:mobile_no
        };

        const validate = validateForm(formData);
        if (validate && agree) {
            signup();
        }
    };

    useEffect(() => {
        setReferralCode(queryParameters.get('code'));
    }, []);

    const signup = async () => {
        setIsloading(true);
        await CustomAxios.post(`signup/`, {
            email: email,
            first_name: first_name==""?"non":first_name,
            last_name: last_name==""?"member":last_name,
            password: password,
            username:username,
            mobileno:mobile_no,
            member:member,
            referralcode: '',
            isactive:false,
            whatsapp: checked ? true : false,
            platform:platform

        }).then(data => {
            setIsloading(false);
            localStorage.setItem("user", JSON.stringify (data.data));
            if (data.data.message === "Registration Successful!") {
                dispatch(updateOtpCheck('signup'));
                dispatch(updateEmail(data.data.email));
          dispatch(updateMobile(data.data.mobileno));
          dispatch(updateId(data.data.user_id));
          dispatch(updateWhatsapp(checked ? true : false));
                navigate("/otp-verification");
            } else {
                setResponse(data.data.message);
            }
        }).catch(error => {
            setIsloading(false);
            // console.log(error);
        });
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const isMobile = useMediaQuery({ maxWidth: 600 });

    const validateForm = (formData) => {
        let valid = true;
        const newErrors = {};
        // if (!formData.first_name.trim()) {
        //     newErrors.first_name = 'Enter your First name.​';
        //     valid = false;
        // }
        // if (!formData.last_name.trim()) {
        //     newErrors.last_name = 'Enter your Last name.​';
        //     valid = false;
        // }
        if (!formData.mobile_no.trim()) {
            newErrors.mobile_no = "Enter your Mobile Number";
            valid = false;
          } 
            // Validation for Indian numbers (must be exactly 10 digits)
           else if (formData.mobile_no.length !== 10) {
              newErrors.mobile_no = "Enter a valid 10-digit Indian Mobile Number";
              valid = false;
            }
          
          
          
        // const emailPattern = /^\S+@\S+\.\S+$/;
        // if (!formData.email.trim()) {
        //     newErrors.email = 'Enter you email address.​';
        //     valid = false;
        // } else if (!emailPattern.test(formData.email)) {
        //     newErrors.email = 'Please enter valid email address';
        //     valid = false;
        // }

        if (!formData.password.trim()) {
            newErrors.password = 'Please enter your password.';
            valid = false;
        } else if (formData.password.length < 8 || !/[A-Z]/.test(formData.password) || !/[a-z]/.test(formData.password) || !/\d/.test(formData.password)) {
            newErrors.password = "The password must be at least 8 characters long and contain uppercase and lowercase letters and numbers.";
            valid = false;
        }
        if (!agree) {
            newErrors.checkbox = "Please agree to terms and Policy.​";
        }
        if (!formData.username.trim()) {
            newErrors.username = "Enter your Profile / Username";
            valid = false;
          } else if (formData.username.length < 3) {
            newErrors.username = "Minimum 3 characters allowed";
            valid = false;
          } else if (formData.username.length > 12) {
            newErrors.username = "Maximum 12 characters allowed";
            valid = false;
          }

        setErrors(newErrors);
        return valid;
    };

    useEffect(() => {
        dispatch(updateEmail(""));
        dispatch(updatePassword(""));
    }, [dispatch]);

    const passwordVisible = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        ),
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSummit();
        }
    };

    const homePage = () => {
        navigate("/");
    };

    // const handleGoogleSuccess = (credentialResponse) => {
    //     const details = jwtDecode(credentialResponse.credential);
    //     // console.log(details);
    //     const { given_name, family_name, email } = details;
    //     // Dispatch these details to update the Redux store or make an API call to register/login the user
    //     // console.log(given_name,family_name,email)
    //     dispatch(updateFirstName(given_name));
    //     dispatch(updateLastName(family_name));
    //     dispatch(updateEmail(email));

    //     // Redirect to the password page
    //     navigate("/");
        
    // };

    // const handleGoogleError = () => {
    //     // console.log('Login Failed');
    // };
    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
      };
      const handleTooltipOpen = () => {
        setOpen(true);
      };
      const handleTooltipClose = () => {
        setOpen(false);
      };
      const memberTooltip = () => {
        setOpenMember(true);
      };
      const memberTooltipClose = () => {
        setOpenMember(false);
      };
      const nonMemberTooltip = () => {
        setOpenNonMember(true);
      };
      const nonMemberTooltipClose = () => {
        setOpenNonMember(false);
      };
    const handleGoogleSuccess = async (credentialResponse) => {
        const details = jwtDecode(credentialResponse.credential);
        // console.log(details);
        const { given_name, family_name, email } = details;      const memberTooltip = () => {
        setOpenMember(true);
      };
      const memberTooltipClose = () => {
        setOpenMember(false);
      };
      const nonMemberTooltip = () => {
        setOpenNonMember(true);
      };
      const nonMemberTooltipClose = () => {
        setOpenNonMember(false);
      };
        // console.log(given_name, family_name, email);
        
        const payload = {
            email: email,
            first_name: given_name,
            last_name: "family_name" in details?family_name:"",
            referralcode: referralCode? referralCode:"",
            is_active: true,
            registration_type: "google",
            platform:platform
        };
        // console.log(payload)
    
        // console.log(given_name);
        // console.log(family_name);
        // console.log(email);
    
        setIsloading(true);
        try {
            const response = await CustomAxios.post(`signup/`, payload);
            setIsloading(false);
    
            if (response.data.message === "Registration Successful!") {
                const decode = jwtDecode(response.data.access_token);
                localStorage.setItem("address", JSON.stringify(response.data.add));
                localStorage.setItem('authtoken', response.data.access_token);
                localStorage.setItem('refreshToken', response.data.refresh_token);
                // console.log(response.data.add);
                // console.log(decode);
                dispatch(updateMemberResponse(response.data));
                dispatch(updateFirstName(decode.first_name));
                dispatch(updateLastName(decode.last_name));
                dispatch(updateEmail(decode.email));
                dispatch(updateRole(decode.role));
                dispatch(updateId(decode.id));
                if (response.data.att.length > 0) {
                    dispatch(updateProfile(response.data.att[0].original));
                }
                dispatch(updateEmail(decode.email));
                navigate("/");
            } else {
                setErrors({ passworderror: response.data.message });
                if(response.data.error){
                    dispatch(updateSnackBarMessage(response.data.error))
                    dispatch(updateSnackBarSeverity("error"))
                  
                  dispatch(updateSnackBarOpen(true))
                }
            }
        } catch (error) {
            setIsloading(false);
            console.error(error);
        }
    };
    



    

    return (
            <GoogleOAuthProvider clientId={clientId}>
                <MessageBar/>
                <Box component={!isMobile && Paper} sx={{ left: "50%", top: {xs:"50%",md:"50%"}, position: "absolute", transform: 'translate(-50%, -50%)', display: "flex", alignItems: { xs: "center", sm: "initial" }, flexDirection: { sm: "row", xs: "column" }, width: { md: "450px", xs: "100%" }, maxWidth: { xs: "100%", md: "450px" }, height: { xs: "auto", sm: "100vh", md: "auto" } }} >
                {isMobile && ( <Box src={background} sx={{ width: { md: "40%", xs: "100%", sm: "40%" }, height: { xs: "50vh", sm: "100vh", md: "auto" }, maxheight: "80vh",overflowY: "scroll", backgroundSize: "auto", objectFit: "fill",  }}     component="img" />)}
                    <Box sx={{ padding: { sm: "24px", xs: "32px" }, display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column', bgcolor: "#fff", width: { sm: "100%", xs: "100%" }}}>
                        <Box src={mayi_logo} component='img' sx={inputStyles.logo} onClick={homePage} />
                        <Typography sx={inputStyles.subHeading} color="grey.900">Join us!</Typography>
                        <Typography sx={inputStyles.constent} color="grey.900"> Create your account and start exploring.</Typography>
                        <Box sx={{alignSelf:"start"}}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={member}
                            onChange={(e)=>dispatch(updateMembership(e.target.value))}
                            >
                                 <Tooltip PopperProps={{  sx: {"& .MuiTooltip-tooltip": { backgroundColor: "#001F38",  color: "#fff",  }, "& .MuiTooltip-arrow": { color: "#001F38",   }, },   }} title={  <Box ref={tooltipRef}  sx={{ backgroundColor: "#001F38", color: "white",  fontSize: "12px", textAlign: "center", padding: "8px", borderRadius: "4px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", maxWidth: "240px",}} ><Typography sx={{ fontSize: "12px",     color: "white",     textAlign: "left",    }} >MAYI IQ Member:</Typography>  <Typography sx={{ fontSize: "12px",     color: "white",     textAlign: "left",    }} > Unlock earning opportunities and special offers. Need to complete KYC verification.    </Typography>   </Box>}arrow open={openMember} onOpen={memberTooltip} onClose={memberTooltipClose}  placement="bottom" >
                            <FormControlLabel value="member" control={<Radio />} label="Become a Member" />
                            </Tooltip>
                            <Tooltip PopperProps={{  sx: {"& .MuiTooltip-tooltip": { backgroundColor: "#001F38",  color: "#fff",  }, "& .MuiTooltip-arrow": { color: "#001F38",   }, },   }} title={  <Box ref={tooltipRef}  sx={{ backgroundColor: "#001F38", color: "white",  fontSize: "12px", textAlign: "center", padding: "8px", borderRadius: "4px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", maxWidth: "240px",}} ><Typography sx={{ fontSize: "12px",     color: "white",     textAlign: "left",    }} >MAYI IQ Basic user:</Typography>  <Typography sx={{ fontSize: "12px",     color: "white",     textAlign: "left",    }} >This option is for casual users who want to explore the platform. No KYC is required.    </Typography>   </Box>}arrow open={openNonMember} onOpen={nonMemberTooltip} onClose={nonMemberTooltipClose}  placement="bottom" >
                            <FormControlLabel value="non_member" control={<Radio />} label="Basic User" />
                            </Tooltip>
                            </RadioGroup>  
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: 'column', gap: "8px", width: "100%" }}>
                            {/* <Box sx={{ display: "flex", gap: "16px", flexDirection: { xs: "column", sm: "row" } }}>
                                <Box>
                                    <Typography sx={inputStyles.label} color="grey.700">First Name <span style={{ color: "red" }}>*</span></Typography>
                                    <InputBase sx={inputStyles.inputBase} placeholder="Enter your First Name" onKeyDown={handleKeyPress} onChange={(e) => dispatch(updateFirstName(e.target.value))} />
                                    {errors.first_name && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.first_name}</Typography>}
                                </Box>
                                <Box>
                                    <Typography sx={inputStyles.label} color="grey.700">Last Name <span style={{ color: "red" }}>*</span></Typography>
                                    <InputBase sx={inputStyles.inputBase} placeholder="Enter your Last Name" onKeyDown={handleKeyPress} onChange={(e) => dispatch(updateLastName(e.target.value))} />
                                    {errors.last_name && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.last_name}</Typography>}
                                </Box>
                            </Box> */}
                            <Box>
                                <Typography sx={inputStyles.label} color="grey.700">
                                    Profile / Username <span style={{ color: "red" }}>*</span>
                                </Typography>
                                <InputBase autoComplete="member_username"value={username}sx={inputStyles.inputBase}placeholder="Set Profile / Username" onChange={(e) =>dispatch(updateUserName( e.target.value.replace(" ", ""),))}/>
                                {errors.username && (<Typography sx={{fontWeight: 400,fontSize: "14px",color: "error.light",mt: "4px",}}>{errors.username}</Typography>)}
                            </Box>
                            {/* <Box>
                                <Typography sx={inputStyles.label} color="grey.700">Email <span style={{ color: "red" }}>*</span></Typography>
                                <InputBase sx={inputStyles.inputBase} placeholder="Enter email address" onKeyDown={handleKeyPress} onChange={(e) => dispatch(updateEmail(e.target.value))} />
                                {errors.email && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.email}</Typography>}
                            </Box> */}
                            <Box>
                                <Typography sx={inputStyles.label}>
                                    Mobile Number <span style={{ color: "red" }}>*</span>
                                </Typography>
                                <InputBase autoComplete="tel-national" value={mobile_no}  onChange={(e) =>{ let cleanedValue = e.target.value.replace(/\D/g, ""); dispatch(updateMobile( cleanedValue)  ); }} sx={inputStyles.inputBase} startAdornment={ selectedCountry && ( <Typography sx={{ pr: "5px", fontSize: "14px", display: "flex", alignItems: "center",  gap: "10px", maxWidth: { xs: "100%", sm: "100%" },  }}>  <img src={selectedCountry.flag}  alt="Country flag"   style={{ width: "20px", height: "15px", marginRight: "5px", }}/>{selectedCountry.value == "US" ? "+1" : selectedCountry.dialCode}   </Typography> ) } placeholder="Enter your mobile number" />   {errors.mobile_no && (
                                    <Typography sx={{  mt: "4px",fontWeight: 400,  fontSize: "14px", color: "error.light",  }}   > {errors.mobile_no}   </Typography>  )}
                                <Box sx={{  display: "flex",    alignItems: "center",  gap: 1,   position: "relative",height:errors.mobile_no&&"20px",  }}> <Box sx={{ display: "inline" }}>
                                <FormControlLabel sx={{height:"25px",marginBottom:"0px"}} control={ <Checkbox checked={checked || selectedCountry?.value !== "IN"} onChange={handleCheckboxChange} disabled={selectedCountry?.value !== "IN"}sx={{  color:  selectedCountry?.value !== "IN" ? "grey.300" : "primary.light",  "&.Mui-checked": {    color: "primary.light", }, "&.Mui-disabled": { color: "grey.300",},  }}  />  } label={  <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "grey.900", textAlign: "left" }} >   Get updates / OTP via WhatsApp{" "}  </Typography>  } />
                                    </Box>  <Box  sx={{ display: "inline", marginLeft: "-23px", marginBottom: "6px", background: "transparent",  }}  >
                                    <Tooltip PopperProps={{  sx: {"& .MuiTooltip-tooltip": { backgroundColor: "#001F38",  color: "#fff",  }, "& .MuiTooltip-arrow": { color: "#001F38",   }, },   }} title={  <Box ref={tooltipRef}  sx={{ backgroundColor: "#001F38", color: "white",  fontSize: "12px", textAlign: "center", padding: "8px", borderRadius: "4px", boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", maxWidth: "240px",}} > <Typography sx={{ fontSize: "12px",     color: "white",     textAlign: "left",    }} > Receive notifications about OTP, offers, updates,  and important account info on WhatsApp.    </Typography>   </Box>}arrow open={open} onOpen={handleTooltipOpen} onClose={handleTooltipClose}  placement="bottom" >
                                        <IconButton sx={{ position: "relative", left: 0,borderRadius: "50%",}} onClick={handleTooltipOpen} >
                                        <InfoIcon sx={{width: "16px",
                                            height: "16px",color: "#53AEFF", }}/>
                                        </IconButton>
                                    </Tooltip>
                                    </Box>
                                </Box>
                            </Box>
                            
                            <Box>
                                <Typography sx={inputStyles.label} color="grey.700">Password <span style={{ color: "red" }}>*</span></Typography>
                                <InputBase type={showPassword ? 'text' : 'password'} placeholder="Enter password" sx={inputStyles.inputBase} onKeyDown={handleKeyPress} onChange={(e) => dispatch(updatePassword(e.target.value))} endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                } />
                                {errors.password && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.password}</Typography>}
                            </Box>
                            {/* <Box>
                                <Typography sx={inputStyles.label} color="grey.700">Sponsor Referral Code</Typography>
                                <InputBase sx={inputStyles.inputBase} value={referralCode} placeholder="Enter Referral Code" onKeyDown={handleKeyPress} onChange={(e) => setReferralCode(e.target.value)} />
                                {errors.referralCode && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.referralCode}</Typography>}
                            </Box> */}
                            <Box>
                                <Box sx={{ display: "flex", alignItems: "center", }}>
                                    <Checkbox
                                        checked={agree}
                                        onChange={() => setAgree(!agree)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                   <Typography sx={inputStyles.terms} ><Link to="/terms-service" style={{ textDecoration: "none", color: "rgba(39, 161, 255, 1)", fontWeight: "400", textDecorationLine: "underline" }} >Terms&nbsp;of&nbsp;Services</Link >&nbsp;and&nbsp;<Link style={{ textDecoration: "none", color: "rgba(39, 161, 255, 1)", fontWeight: "400", textDecorationLine: "underline" }} to="/privacy-policy">Privacy Policy</Link></Typography>
                                </Box>
                                {errors.checkbox && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.checkbox}</Typography>}
                            </Box>
                            <Typography sx={{ color: "error.light" }}>{response}</Typography>
                            
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Button variant="contained" sx={inputStyles.button} onClick={handleSummit} >{isloading ? <CircularProgress color="inherit" size={30} /> : "Sign up"}</Button>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                                <Typography sx={{ textAlign: "center", fontSize: "14px" }}> Already have an account?&nbsp;</Typography><Link to="/login" style={{ textDecoration: "none", textDecorationLine: "underline", fontSize: "14px" }}><Typography sx={{ textDecoration: "none", fontSize: "14px" }} color="primary">Sign in</Typography></Link>
                            </Box>
                            <Box  sx={{display:"flex",flexDirection:"column",gap:"8px"}}>
                            <Divider sx={{ fontSize: "14px" }} ><Typography>Or</Typography> </Divider>
                            <Box sx={{ display: "flex", justifyContent: "center",  }}>
                                <GoogleLogin
                                    onSuccess={handleGoogleSuccess}
                                    // onError={handleGoogleError}
                                />
                                </Box>
                            </Box>
                            {/* <Divider sx={{ marginTop: "24px", marginBottom: "20px", fontSize: "14px" }} >Or</Divider> */}
                            
                        </Box>
                    </Box>
                </Box>
            </GoogleOAuthProvider>
        );

};
export default SignupForm;