import { Modal, Box, Typography, InputBase, Button, RadioGroup, Radio, FormControlLabel } from "@mui/material"
import React, { useEffect, useState,useRef,useCallback } from "react";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import { updateAddress, updateAddressModal, updateFullAdress, updateAllAddress } from "../redux/user_reducer";
import { formStyle } from "../modules/profile";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import CustomAxios from '../utils/CustomAxios';
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
const Address = () => {
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch()
    const addressModal = useSelector((state) => state.user.addressModal)
    const address = useSelector(state => state.user.address)
    const [errors, setErrors] = useState({})
    const id = useSelector(state => state.user.id)
    const res=useSelector(state=>state.user.member_response)
    const typeEnable=useSelector(state=>state.user.typeEnable)
  
// console.log(res)
    // // console.log(addressModal)
    useEffect(() => {
        // // console.log(addressModal)
        setOpen(addressModal);
        if(addressModal==false){
            setErrors({})
        }
    }, [addressModal])
    const handleOpen = () => {
        setOpen();
    };
    const handleClose = () => {
        dispatch(updateAddressModal(false))
        setOpen(false);
    };
    const validation = (address) => {

        let valid = true;
        const newErrors = {};
        // // console.log(formData)
        // Name validation
        // if (!address.address_type?.trim()) {
        //     newErrors.address_type = 'Please Select Address Type';
        //     valid = false;
        // }
        if (!address.title?.trim()) {
            newErrors.title = 'Please Enter your title here';
            valid = false;
        }

        if (!address.town_city?.trim()) {
            newErrors.town_city = 'Please Enter your city here';
            valid = false;
        }
        // if (!address.country?.trim()) {
        //     newErrors.country = 'Please Enter your country here';
        //     valid = false;

        // }
        if (!address.pin?.trim()) {
            newErrors.pin = 'Please Enter your Zipcode here';
            valid = false;

        }
        else if(address.pin?.length<6) {
            newErrors.pin = 'Please provide valid PIN number';
            valid = false;

        }
        else if(address.pin?.length>6) {
            newErrors.pin = 'Maximum 6 Character Allowed';
            valid = false;

        }
        if (!address.address?.trim()) {
            newErrors.address = 'Please Enter your street here';
            valid = false;

        }
        if (!address.state?.trim()) {
            newErrors.state = 'Please Enter your state here';
            valid = false;

        }
        // // console.log(address?.mobileno?.length)
        if (address?.mobileno?.length < 10){
            newErrors.mobile = 'Enter a 10-digit number only.'
            valid = false;
        }
        if (address?.mobileno?.length > 10 || !address.mobileno?.trim()){
            newErrors.mobile = 'Enter a valid mobile numer'
            valid = false;
        }


        // // console.log(newErrors)
        //
        // // Set errors state
        setErrors(newErrors);

        return valid;



    }
    const handleSubmit = (del) => {

        // handleClose()

        const validate = validation(address)
        if (validate) {
            submitAddress(del)
        }
        

    }

    const CheckChanges = (id)=>{
        // console.log("---------res-------",res)
        let add_obj = res.add?.filter(e=>e.id === id)?.[0]
        // console.log("------------------",add_obj,address)
        let checking_keys = ['title','mobileno','town_city','state','pin','country','address']
        let is_changed = false
        Object.keys(address).map((key)=>{
            if(checking_keys.includes(key) && address[key].replace(' ','').toLowerCase() !== add_obj[key].replace(' ','').toLowerCase()){
                is_changed = true
            }
        })
        // console.log("----------is_changed------",is_changed)
        return is_changed
    }


    const submitAddress = async (del) => {
// // console.log(del)
        let message = ""
        let severity = ""
        if (address.id && del!=='delete') {
            let isChangesDetected = CheckChanges(address.id)
            if(isChangesDetected){

                await CustomAxios.patch(`addressupdate/${address.id}`, {
                    account: res?.account?.id,
                    // address_type: address.address_type,
                    address: address.address,
                    title: address.title,
                    country: "India",
                    town_city: address.town_city,
                    state: address.state,
                    pin: address.pin,
                    mobileno: address.mobileno,
                    created_by: res?.user?.id,
                    updated_by: res?.user?.id
    
                }).then((res) => {
                    if(res.status === 200){
                        handleClose()
                        message = "Address updated successfully."
                        severity = "success"
                    }
                    else{
                        message = "Address updation failed."
                        severity = "error"
                    }
                }).catch((err)=>{
                    message = "Contact customer support."
                    severity = "error"
                })

            }
            else{
                message = "No changes detected."
                severity = "error"
            }

        }
        else if( del!=='delete') {
            await CustomAxios.post(  `address/`, {
                account: res?.account?.id,
                // address_type: address.address_type,
                address: address.address,
                title: address.title,
                country: "India",
                town_city: address.town_city,
                state: address.state,
                pin: address.pin,
                mobileno: address.mobileno,
                created_by: res?.user?.id,
                updated_by:res?.user?.id

            }).then((res) => {
                if(res.status === 201){
                    handleClose()
                    message = "Address added successfully."
                    severity = "success"
                }
                else{
                    message = "Address addtion failed."
                    severity = "error"
                }
            }).catch((err)=>{
                message = "Contact customer support."
                severity = "error"
            })

        }
        else if(del=='delete'){
            await CustomAxios.delete(`addressupdate/${address.id}`).then((res) => {
                if(res.status === 204){
                    handleClose()
                    message = "Address deleted successfully."
                    severity = "success"
                }
                else{
                    message = "Address deletion failed."
                    severity = "error"
                }
            }).catch((err)=>{
                message = "Contact customer support."
                severity = "error"
            })
            localStorage.removeItem("address")
        }

        dispatch(updateSnackBarMessage(message))
        dispatch(updateSnackBarSeverity(severity))
        dispatch(updateSnackBarOpen(true))

        if(res.account){

            const useraddress = await CustomAxios.get(`address/${res.account?.id}`)
            // // console.log(useraddress)
            localStorage.setItem("address", JSON.stringify(useraddress.data))
            dispatch(updateAllAddress(useraddress.data))
        }
    }

    const isMobile = useMediaQuery({ maxWidth: 600 });


    const handlePinCodeVerification = useCallback(async () => {
        if (address.pin.length === 6) {
            try {
                const response = await fetch(`https://api.postalpincode.in/pincode/${address.pin}`);
                const data = await response.json();
                // console.log(data);
    
                if (data[0].Status === "Success") {
                    const postOffice = data[0].PostOffice[0];
                    let updatedAddress = { ...address, state: '', town_city: '', pin: address.pin };
    
                    updatedAddress = {
                        ...updatedAddress,
                        state: postOffice.State,
                        town_city: postOffice.District
                    };
    
                    dispatch(updateAddress(updatedAddress));
                } else {
                    setErrors({ pin: "Invalid PIN code. Please provide a valid 6 digit PIN code." });
                    dispatch(updateAddress({ ...address, state: '', town_city: '' }));
                }
            } catch (error) {
                setErrors({ pin: "Error fetching address details. Please try again." });
                console.error("Error fetching address details:", error);
            }
        } else {
            setErrors({ pin: "Please enter a 6 digit PIN code." });
        }
    }, [address.pin, dispatch, address]);
    
    
      const handleKey = (e) =>{
        if(e.key === 'Enter'){
            handleSubmit()
        }
      }
      const handleNumbers = (e,field) =>{
        const value = e.target.value
        const regex = /^[0-9]*$/;
        if (regex.test(value)) {
            if (field === "mobileno") {
                if (value.length <= 10) {
                    setErrors({ mobile: "" });
                    dispatch(updateAddress({ ...address, mobileno: e.target.value }));
                } else {
                    setErrors({ mobile: "Enter a 10-digit number only." });
                }
            } else if (field === "pin") {
                if (value.length <= 6) {
                    setErrors({ });
                    dispatch(updateAddress({ ...address, pin: e.target.value }));
                } else {
                    console.log(field);
                    setErrors({ pin: "Enter a 6-digit number only." });
                }
            }
        } else {
            if (field === "mobileno") {
                setErrors({ mobile: "Enter a valid 10-digit number." });
            } else if (field === "pin") {
                console.log(field);
                setErrors({ pin: "Enter a valid 6-digit number." });
            }
        }
      }
    return (
                <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            sx={{zIndex:"21 !important"}}
        >
            <Box sx={{ width: isMobile ? "calc(100% - 16px)" : "600px", background: "#fff", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)', padding: "32px", border: "none" ,overflowY:"auto",height:{xs:"95%",sm:"max-content"}}}>
                <Typography sx={{ textAlign: "center",paddingBottom:"10px" }}>{address.id ? "Edit Address" : "Add New Address"}</Typography>
                <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}>
                {/* {typeEnable||address.address_type=="billing"?
                    <Box >
                        <Typography sx={formStyle.label} color="grey.700"> Type</Typography>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            sx={{ flexDirection: "row" }}
                            value={address.address_type}
                            defaultValue="billing"
                            onChange={(e) => dispatch(updateAddress({ ...address, address_type: e.target.value}))}
                        >
                            <FormControlLabel value="billing" defaultChecked control={<Radio disabled={res.add?.[0].address_type === "billing"?true:false} />} label="Billing Address" />
                            <FormControlLabel value="shipping" control={<Radio disabled={res.add?.[0].address_type === "billing"?true:false} />} label="Delivery Address" />
                        </RadioGroup>
                        {errors.address_type && <Typography sx={{ color: "error.light" }}>{errors.address_type}</Typography>}
                    </Box>:null} */}

                    <Box sx={{display:"flex", flexDirection:{xs:"column",md:"row"}, width: "100%", gap: "36px" }}>
                        <Box>
                        <Typography sx={formStyle.label} color="grey.700"> Title <Typography component="span" sx={{color:"#EF4444"}}>*</Typography></Typography>                        
                        <InputBase onKeyDown={(e)=>handleKey(e)} sx={formStyle.inputBase}  value={address.title? address.title : typeEnable?"Home":null} onChange={(e) =>{ dispatch(updateAddress({ ...address, title: e.target.value }))}} placeholder="e.g., Home / Office"/>
                        {errors.title && <Typography sx={{ color: "error.light" }}>{errors.title}</Typography>}
                    </Box>
                    <Box>
                    <Typography sx={formStyle.label} color="grey.700"> Mobile No <Typography component="span" sx={{color:"#EF4444"}}>*</Typography></Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>

                    <Box sx={{display:"flex", flexDirection:"column", gap:"8px", width:"100%"}}>
                    <Box sx={{display:"flex", flexDirection:"row"}}>
                        {/* <InputBase value="+91" disabled sx={{ border: "1px solid", borderColor: "grey.400", width: "60px", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" }}> </InputBase> */}
                        <InputBase onKeyDown={(e)=>handleKey(e)} sx={formStyle.inputBase} startAdornment={<Typography sx={{pr:"5px",fontSize:"16px"}} >+91</Typography>} value={address.mobileno} onChange={(e) => handleNumbers(e,'mobileno')} placeholder="Enter Your Mobile Number"/>
                        </Box>
                        {errors.mobile && <Typography sx={{ color: "error.light" }}>{errors.mobile}</Typography>}
                        </Box>
                    </Box>
                    </Box>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <Typography sx={formStyle.label} color="grey.700">
                            ZIP <Typography component="span" sx={{ color: "#EF4444" }}>*</Typography>
                        </Typography>
                        <InputBase
                            onKeyDown={(e) => handleKey(e)}
                            maxLength={6}
                            sx={formStyle.inputBase}
                            value={address.pin}
                            onChange={(e) => handleNumbers(e, 'pin')}
                            onBlur={handlePinCodeVerification}
                            placeholder="Enter 6 digits PIN code to search"
                        />
                        {errors.pin && <Typography sx={{ color: "error.light" }}>{errors.pin}</Typography>}
                    </Box>

  
                        <Box>
                        <Typography sx={formStyle.label} color="grey.700">Street Address <Typography component="span" sx={{color:"#EF4444"}}>*</Typography></Typography>

                        <InputBase  sx={formStyle.inputBase} value={address.address} onChange={(e) => dispatch(updateAddress({ ...address, address: e.target.value }))} placeholder="Enter Street Name or Nearby Landmark"/>
                        {errors.address && <Typography sx={{ color: "error.light" }}>{errors.address}</Typography>}

                        {/* {errors.userName && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} */}
                    </Box>
                    <Box sx={{ display: "flex", width: "100%", gap: "36px" }}>
                   

                        <Box sx={{ width: "100%" }}>
                            <Typography sx={formStyle.label} color="grey.700"> City <Typography component="span" sx={{color:"#EF4444"}}>*</Typography></Typography>
                            <InputBase onKeyDown={(e)=>handleKey(e)} sx={formStyle.inputBase} value={address.town_city} onChange={(e) => dispatch(updateAddress({ ...address, town_city: e.target.value }))} />
                            {errors.town_city && <Typography sx={{ color: "error.light" }}>{errors.town_city}</Typography>}
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex",flexDirection:{xs:"column",md:"row"}, width: "100%", gap: "36px" }}>


                        <Box sx={{ width: "100%" }}>
                            <Typography sx={formStyle.label} color="grey.700"> State <Typography component="span" sx={{color:"#EF4444"}}>*</Typography></Typography>
                            <InputBase onKeyDown={(e)=>handleKey(e)} sx={formStyle.inputBase} value={address.state} onChange={(e) => dispatch(updateAddress({ ...address, state: e.target.value }))} />
                            {errors.state && <Typography sx={{ color: "error.light" }}>{errors.state}</Typography>}
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <Typography sx={formStyle.label} color="grey.700"> Country <Typography component="span" sx={{color:"#EF4444"}}>*</Typography></Typography>
                            <InputBase onKeyDown={(e)=>handleKey(e)} readOnly sx={formStyle.inputBase} value={"India"} onChange={(e) => dispatch(updateAddress({ ...address, country: e.target.value }))} />
                            {errors.country && <Typography sx={{ color: "error.light" }}>{errors.country}</Typography>}
                        </Box>
                    </Box>
                   
                    <Box sx={{display:"flex",width:"100%",gap:"24px",justifyContent:"space-between",flexDirection:"column"}}>
                    {errors.countryerr && <Typography sx={{ color: "error.light" }}>{errors.countryerr}</Typography>}
                    <Box sx={{display:"flex",width:"100%",gap:"24px",justifyContent:"space-between"}}>
                        {address.id&&<Button sx={ { height: "3rem",padding:"0rem 1.25rem",textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end",borderRadius:"5px" }} color="error" variant="contained" onClick={()=>handleSubmit('delete')} >Delete</Button>}
                        <Box sx={{display:"flex", gap:"8px"}}>
                        <Button sx={ { height: "3rem",padding:"0rem 1.25rem",textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end",borderRadius:"5px" }} color="primary" variant="contained" onClick={handleClose} >Cancel</Button>
                        <Button sx={ { height: "3rem",padding:"0rem 1.25rem",textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end",borderRadius:"5px" }} color="primary" variant="contained" onClick={handleSubmit} >{address.id ? "Edit" : "Add"}</Button>
                    </Box>
                        </Box>
                    </Box>



                </Box>
            </Box>
        </Modal>
    )


}

export default Address
