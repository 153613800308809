import { BottomNavigation, BottomNavigationAction, Box, Paper ,Badge,IconButton} from "@mui/material";
import { useState } from "react";
import RestoreIcon from '@mui/icons-material/Restore';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PermIdentityRoundedIcon from '@mui/icons-material/PermIdentityRounded';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { UseSelector, useDispatch, useSelector } from "react-redux";
import { updateSideBar } from "../redux/active_bar";
import { updateCartSideBar } from "../redux/active_bar";
import { useNavigate } from "react-router-dom";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { Link } from "react-router-dom";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ordersIcon from '../assets/adminSidebar/orderManagement.svg'
const BottomBar = () => {
    const member=useSelector(state=>state.user.member)
    const cart = useSelector(state=>state.product.cart)
    const [value, setValue] = useState(0);

    const dispatch = useDispatch()
    const sidebar = useSelector(state => state.sideBar.sideBar)
    const authtoken=localStorage.getItem('authtoken')
    const navigate = useNavigate()
    const handleSidebar = () => {
        if(authtoken){
            dispatch(updateSideBar(true))
        }
        else
        {
            navigate('/login')
        }
       
    }
    const homepage=()=>{
        navigate("/")
    }

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 ,zIndex:20,}} elevation={6}>
            <Box sx={{ padding: "20px", display: "flex", justifyContent: "space-between" }}>


                <HomeOutlinedIcon sx={{ fontSize: "32px" ,color:"#3F4752"}} onClick={homepage} />
                
                {member=='member'?  <IconButton sx={{padding:"0px"}}
                       
                        component={Link} to="/dashboard" 
                      
                      
                    >
                     <DashboardOutlinedIcon sx={{color:"#000",fontSize:"32px"}} />
                    </IconButton>:<Link to="/orderstatus" > <Box  component={'img'} src={ordersIcon} sx={{width:"24px"}} /></Link>}
                    
                
                <Badge badgeContent={cart.length} color="primary">
                <ShoppingCartOutlinedIcon onClick={() => authtoken?dispatch(updateCartSideBar(true)):navigate("/login")} sx={{ fontSize: "32px",color:"#3F4752"}} /></Badge>
                <PermIdentityRoundedIcon sx={{ fontSize: "32px",color:"#3F4752" }} onClick={handleSidebar} />
            </Box>

        </Paper>
    )



}

export default BottomBar