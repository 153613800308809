import * as React from 'react';
import { useEffect,useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, Box,Stack,InputBase,MenuItem,Button,Typography,Select ,Divider,TablePagination} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Delete, DeleteOutline, Edit, EditNote, Filter, Search } from '@mui/icons-material';
import CustomAxios from '../utils/CustomAxios';
import { useDispatch, useSelector } from 'react-redux';
import { updateAllOrders,updateFilteredOrders } from '../redux/orders';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom"
import { Calendar } from "primereact/calendar"
import { all } from 'axios';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import orderOverview from "../assets/adminSidebar/orderoverview.svg"
import overviewIcon from "./../assets/adminSidebar/overview.svg"
export default function OrderList({ texttoFilter }) {
  const dispatch = useDispatch()
  const allOrders = useSelector(state => state.orders.AllOrders)
  const navigate = useNavigate()
  const [filteredOrder,setFilteredOrder]=useState([])
  const [isFilter,setIsFilter]=useState(false)
  const [loading, setLoading] = useState(false); // Prevent multiple fetches
  const [offset, setOffset] = useState(0); // Tracks how much data has been loaded

  const [totalOrder, setTotalOrder] = useState(true); 

  const [cancelled, setCancelled] = useState(true);
  const [inprogress, setInprogress] = useState(true); 
  const [completed, setCompleted] = useState(true);
  const tbl_div=React.useRef()
  const [shipped,setShipped]=useState(0)
  useEffect(() => {
    getOrders()
  }, [])
  const [Filters,setFilters] = useState({
    search:"",
    status:"",
    payment:"",
    dateRange:[]
  })
  let paperHeight=React.useRef()
  const [pastFilter,setPastFiters]=useState(false)
  const getOrders = () => {
    CustomAxios.post('listOrders/',{limit:20,offset:0}).then((res) => {

      dispatch(updateAllOrders(res.data.allOrder))
      setOffset(res.data.allOrder.length)
      setCompleted(res.data.completed)
      setCancelled(res.data.cancelled)
      setTotalOrder(res.data.totalOrder)
      setInprogress(res.data.inprogress)
      setShipped(res.data.shipped)



    })
  }
  useEffect(()=>{
    
   if (pastFilter) {
   if (isFilter&&loading ){
    CustomAxios.post('listOrders/',{limit:20,offset:offset,filter:Filters}).then((res) => {
      setFilteredOrder([...filteredOrder,...res.data.allOrder])
      dispatch(updateFilteredOrders([...filteredOrder,...res.data.allOrder]))
      setOffset([...filteredOrder,...res.data.allOrder].length)
      setLoading(false)
      // setData((prev)=>[...prev,...res.data])
    })
   }
   else if(loading && offset<=totalOrder){
   
    CustomAxios.post('listOrders/',{limit:20,offset:offset}).then((res) => {
      dispatch(updateAllOrders([...allOrders,...res.data.allOrder]))
      setOffset([...allOrders,...res.data.allOrder].length)
      setLoading(false)

      // setData((prev)=>[...prev,...res.data])
    })
   
   }
   else{
    // console.log("????????????????full orders")
    setLoading(false)
}
setPastFiters(false)
   }
  //  else{
  //   setLoading(false)
  //  }
    
},[pastFilter])
useEffect(()=>{
  let filter=false
 
  if (isFilter && Filters.status!==""||Filters.payment!==""||Filters.search!==""||Filters.dateRange.length>1){
    // console.log('filter setup')
    filter=isFilter
  }
  
  // if (isFilter){
    if (Filters.status==""&&Filters.payment==""&&Filters.search==""&&Filters.dateRange.length<1){
      setIsFilter(false)

    }
    else{
      setIsFilter(true)
    }
   
    if(filter || Filters.status!==""||Filters.payment!==""||Filters.search!==""||Filters.dateRange.length>1){
      
      CustomAxios.post('listOrders/',{limit:20,offset:0,filter:Filters}).then((res) => {
        setFilteredOrder(res.data.allOrder)
        dispatch(updateFilteredOrders(res.data.allOrder))
        // setIsFilter(true)
        
        setOffset([...filteredOrder,...res.data.allOrder].length)
        
      })
    }
    else{
      dispatch(updateFilteredOrders([]))
      
      setFilteredOrder([])
      setOffset(filteredOrder.length)
    }
    
  // }

},[Filters])

  const handlePlaceholder = (val,placeholder)=>{

    if(!val){
      return <Typography sx={{color:"#64748B",fontSize:"14px",fontWeight:400}}>{placeholder}</Typography>
    }
    else {
      if (val=='cashfree'){
        return "Online Payment"
      }
      else if(val==="mayicash"){
        return "Mayi Cash"
      }
      else if(val==="cod"){
        return "Cash On Delivery"

      }
      else if(val==="cash"){
        return "Cash On Pickup"
      }
      else{
        return val
      }
    }
    

    
  }

  useEffect(()=>{
    
    if (Filters.status==""&&Filters.payment==""&&Filters.search==""&&Filters.dateRange.length<1){
      setPastFiters(true)
    }
    else  {
      setIsFilter(true)
      setPastFiters(true)
    }
    console.log(allOrders.length>=totalOrder,!isFilter ,pastFilter)
    if ((allOrders.length>=totalOrder&&!isFilter)||(pastFilter&&isFilter) ){
      setLoading(false)
    }
  },[loading])

  const handleScroll = (e) => {
    const divElement = tbl_div.current;
    const paper=paperHeight.current
    console.log(paper.clientHeight)
    // console.log("?????????????????????????hello to all",divElement.scrollTop + divElement.clientHeight != divElement.scrollHeight,divElement.scrollTop + divElement.clientHeight , divElement.scrollHeight)
    if (Math.round(divElement.scrollTop + divElement.clientHeight)+250 >= divElement.scrollHeight) {
     
        setLoading(true)
      
      
      // lazyLoading()

    }
  };
  useEffect(() => {
    const container = tbl_div.current;
    // console.log("inside event listener for checking")
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // console.log(paperHeight)
  return (
    <Box  ref={paperHeight}>
       <Box sx={{display:"flex",gap:"10px",alignItems:"center",margin:"12px 0px"}}>
            <Box component={'img'} src={overviewIcon} sx={{}} />
            <Typography sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: "700" }}>
                  Orders Overview
            </Typography>
                </Box>
      <Box sx={{display:"flex",flexDirection:"column",gap:"24px"}}>
      
        <Box sx={{ padding: "16px",display:"flex",flexDirection:"column",gap:"16px"}}>
        
        
      <Box sx={{display:"flex",gap:"10px",alignItems:"center"}}>
            <Box component={'img'} src={orderOverview} sx={{}} />
            <Typography sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: "600" }}>
                  Order Details
            </Typography>
                </Box>
      <Box sx={{  display: "flex", justifyContent: "space-around", borderRadius: "5px" }}>
      
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                    {totalOrder}
                </Typography>
                <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                    Total Orders
                </Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                    {inprogress}
                </Typography>
                <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                    In Progress
                </Typography>

            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                {shipped}
                </Typography>
                <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                    Shipped
                </Typography>

            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                {completed}
                </Typography>
                <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                    Delivered
                </Typography>

            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                {cancelled}
                </Typography>
                <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                    Cancelled
                </Typography>

            </Box>
            {/* <Divider orientation="vertical" variant="middle" flexItem />
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "24px", fontWeigt: "32px", fontWeight: "500" }}>
                    {allOrders?.filter(item => item.status=='Payment pending').length}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeigt: "20px", fontWeight: "400" }}>
                        Payment Pending
                    </Typography>

                </Box> */}
          </Box>

          </Box>
          <Box sx={{ display: "flex", flexDirection: "column",gap:"16px"}}>
          <Typography sx={{fontSize:"16px",lineHeight:"24px",fontWeight:"600"}}>Order List</Typography>
          
          
        <Stack direction={'row'} sx={{ justifyContent: "space-between", alignItems: "center" }}>

          <Stack direction={'row'} gap={'20px'}>
            <InputBase inputProps={{ className: 'product-search' }} value={Filters.search} startAdornment={<Search sx={{ color: "#53AEFF",borderRadius:"4px" }} />} placeholder='Order Id / Customer Name ' onChange={(e) => { setFilters({ ...Filters, search: e.target.value.trim(" ") }) }} sx={{ p: "8px 12px", gap: "8px", border: "1px solid #CBD5E1", borderRadius: "4px", height: "36px", }} />
            <Select displayEmpty value={Filters.status} sx={{ gap: "8px", borderRadius: "4px", height: "36px", width: "185px", "& .MuiSelect-select": { display: "flex", alignItems: "flex-end" } ,"& .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1",},"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#CBD5E1",},"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:"#CBD5E1"}}} onChange={(e) => setFilters({ ...Filters, status: e.target.value })} renderValue={(val) => handlePlaceholder(val, 'Status')}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Order Shipped">Order Shipped</MenuItem>
              <MenuItem value="Order Completed">Order Completed</MenuItem>
              
              <MenuItem value="Order Cancelled">Order Cancelled</MenuItem>
            </Select>
            <Select displayEmpty value={Filters.payment} sx={{ gap: "8px", borderRadius: "4px", height: "36px", width: "185px", "& .MuiSelect-select": { display: "flex", alignItems: "flex-end" },"& .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1",},"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#CBD5E1",},"&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor:"#CBD5E1"} }} onChange={(e) => setFilters({ ...Filters, payment: e.target.value })} renderValue={(val) => handlePlaceholder(val, 'Payment Method')}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="cashfree">Online Payment</MenuItem>
              <MenuItem value="mayicash">Mayi Cash</MenuItem>
              <MenuItem value="cash">Cash On Pickup</MenuItem>
              <MenuItem value="cod">Cash On Delivery</MenuItem>

            </Select>
            <Box sx={{ display: "flex", position: "relative", alignItems: "center",maxWidth: "220px",width:"100%" }}>
  
            <Calendar placeholder="Data Range" value={Filters.dateRange} style={{height:"36px",}} onChange={(e) =>setFilters({ ...Filters,dateRange:e.value})} selectionMode="range" readOnlyInput hideOnRangeSelection />
            <CalendarTodayOutlinedIcon sx={{position:"absolute",right:"10px",pointerEvents: "none",color:"grey",width:"20px",height:"20px"}}/></Box>
          </Stack>
          {/* <Button sx={{ p: "8px 12px", textTransform: "none", borderRadius: "4px", "&:hover": { color: "#fff" } }} variant="contained" component={Link} to="/product/new" >+ Create Product</Button> */}
        </Stack>
      <Box>
     
      <TableContainer sx={{ bgcolor: "#fff", height: paperHeight.current?.clientHeight<600?"40vh":"43vh", overflowY: "auto" }} ref={tbl_div}>
      {/* <InfiniteScroll
      dataLength={allOrders?.filter(item => item.status!=='Payment pending').length}
      next={fetchData}
      hasMore={hasMore}
      loader={<h4>Loading...</h4>}
      endMessage={<p>No more data to load</p>}
    > */}
        <Table sx={{ }} stickyHeader aria-label="sticky table"  >
          <TableHead sx={{ backgroundColor: "rgba(226, 232, 240, 1)" }}>
            <TableRow sx={{ backgroundColor: "rgba(226, 232, 240, 1)"}}>
              {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>Tracking Number</TableCell> */}
              <TableCell sx={{ fontWeight: "600", textAlign: "left",background: "#E2E8F0" }}>S.No</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "left",background: "#E2E8F0" }}>Order Id</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center",background: "#E2E8F0" }} align="right">Created On</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center",background: "#E2E8F0" }} align="right">Customer Name</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center",background: "#E2E8F0" }} align="right">Status</TableCell>
              
              {/* <TableCell sx={{ fontWeight: "600", textAlign: "center",background: "#E2E8F0" }} align="right">Delivery Fee</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center",background: "#E2E8F0" }} align="right">Total</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center",background: "#E2E8F0"}} align="right">Status</TableCell>
              <TableCell sx={{ fontWeight: "600", textAlign: "center",background: "#E2E8F0" }} align="right">Actions</TableCell> */}

            </TableRow>
          </TableHead>
          <TableBody>
            {
                (isFilter||filteredOrder.length>0?filteredOrder: allOrders)?.map((order, o_id) => <TableRow
                key={o_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 },cursor:"pointer" }}
                onClick={() => navigate(`${order.razorpay_order_id || order.cash_pickup_id || order.order_id}`)}
              >

                  
                    {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>
                    <InputBase fullWidth  endAdornment={<Edit sx={{width:"16px",height:"16px",cursor:"pointer"}} onClick={()=>// console.log("edit clicked for awb")}/>} sx={{border: "1px solid", borderColor: "grey.400", width: "100%", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" }} />
                  </TableCell> */}
                  <TableCell sx={{ fontWeight: "600", textAlign: "left" }}>{o_id+1}</TableCell>
                    <TableCell sx={{ fontWeight: "600", textAlign: "left" }}>{order.razorpay_order_id || order.cash_pickup_id || order.order_id}</TableCell>
                    <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{new Date(order.created_on).toLocaleDateString()}</TableCell>
                    <TableCell sx={{ fontWeight: "600", textAlign: "center",textTransform:"capitalize" }}>{ order?.user_details?.username }</TableCell>
                    {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{order.carts.length}</TableCell> */}
                    
                    {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{parseFloat(order.delivery_charge)}</TableCell> */}
                    {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{parseFloat(order.total_amount)}</TableCell> */}
                    <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{order.status}</TableCell>
                    {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>
                      {order.status === "Payment pending" ?
                        <VisibilityOff sx={{ color: "#6c6c6c80" }} />
                        :
                        <Visibility sx={{ color: "#6c6c6c", cursor: "pointer" }}  />
                      }
                    </TableCell> */}
                  </TableRow>
                )
              }
             

          </TableBody>
        </Table>
        {/* </InfiniteScroll> */}
        {
        loading&& <Box sx={{display :"flex",alignItems:"center",justifyContent:"center"}}>
        <CircularProgress color="inherit" />
    </Box>
      }
      </TableContainer>
      
     
     
      {/* <TablePagination
        
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredOrder?filteredOrder.length: allOrders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      </Box>
      </Box>
      </Box>
    </Box>

  );
}
