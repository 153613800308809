import { Box, Button, Typography, Modal } from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';      
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import CustomAxios from '../../utils/CustomAxios';
import { useMediaQuery } from "react-responsive";
import { updateMobile } from "../../redux/user_reducer";
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../../redux/snackbar";



const Mobile_EmailVerify = ({verify="mobile",open=false,whatsapp=false, mobile = ""})=>{
    const dispatch = useDispatch()
    const [openOtpModal,setOpenOtpModal] = useState(open)
    let  email = useSelector(state => state.user.email)
    // let  mobile = useSelector(state => state.user.mobile_no)
    let id = parseInt(useSelector(state => state.user.id))
    const [otp,setOtp]=useState('')
    const [resend,setResend]=useState(0)
    const [verification,setVerification]=useState(false)
    const [time, setTime] = useState();
    const [otpNotmatch, setOtpNotMatch] = useState(false)
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const [isloading, setIsloading] = useState(false)

    const verifyRequest=(verify)=>{
        setOtp("")
        const payload={id:id,email:email,verify:verify,mobileno:mobile,dialcode:"+91",whatsapp:true}
        CustomAxios.post("verify/",payload).then(data=>setVerification(true))

    }

    const handleResendOtp =(e) => {
        e.preventDefault()
        verifyRequest(verify)
        setTime(30)
    }
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const get_details = ()=>{
        CustomAxios.post(`get_details/`, {"user_id": id,"admin":true}).then((res)=>{
            dispatch(updateMobile(res.data.user.mobileno))
        })
    }


    const handleSave = async(verify)=>{
        const form = new FormData();
        form.append("user_id",id)
        verify === "mobile" ? form.append("mobileno", mobile) : form.append("email", email)
        const updateresponse = await CustomAxios.post(`update_profile/`, form)
        if (updateresponse.data.message == "User Profile succesfully updated without email") {
            dispatch(updateSnackBarMessage("Mobile updated successfully!"))
            dispatch(updateSnackBarSeverity('success'))
            get_details()
            setOpenOtpModal(false)

        }
        else if(updateresponse.data.message == "could not find user"){
            dispatch(updateSnackBarMessage("Mobile updation failed!"))
            dispatch(updateSnackBarSeverity('error'))
        }
        dispatch(updateSnackBarOpen(true))
    }



    const handleComplete = async(value) => {
        setIsloading(true)
        let payload={
            id:id,
            email:email,
            otp:otp
        }
        CustomAxios.post('otp-verification/',payload).then(data=>{
            if (data.data.message=="Congratulations, your account have been successfully verified"){
                if (verify=="email"){
                    // setEmailVerified(true)
                    setOtpNotMatch(false)
                    // setErrors({})
                }
                if(verify=="mobile"){
                    // setMobileVerified(true)
                    setOtpNotMatch(false)
                    // setErrors({})
                }
                handleSave(verify)
            }
            else{
                setOtpNotMatch(true)

            }
            setIsloading(false)
            
        }).catch((err)=>{
            setIsloading(false)
        })
    }

    useEffect(()=>{
        setOpenOtpModal(open)
        if(open){
            verifyRequest(verify)
            setTime(30)
        }
    },[open])

    useEffect(() => {
        if (time > 0) {
          const timerId = setInterval(() => {
            setTime(prevTime => prevTime - 1);
          }, 1000);
          return () => clearInterval(timerId); // Cleanup interval on component unmount
        }
        else{
            setResend(true)
        }
      }, [time]);

    const maskEmail = (email) => {       //--------------> This is for masking email
        const [localPart, domain] = email.split('@');
        if (localPart.length <= 2) {
            return `${localPart}@${domain}`; 
        }
        const visiblePart = localPart.slice(-2); 
        const maskedPart = 'X'.repeat(localPart?.length - 2);
        return `${maskedPart}${visiblePart}@${domain}`;
    };
      const maskMobileNo = (mobileNumber) => {     //-----------> This is for masking mobile
        if (mobileNumber?.length <= 2) {
            return mobileNumber; 
        }
        
        const visiblePart = mobileNumber?.slice(-2); 
        const maskedPart = 'X'.repeat(mobileNumber?.length - 2); 
        return `${maskedPart}${visiblePart}`;
    };

    return(
        <Modal open={openOtpModal} sx={{}}>
            <Box sx={{ width: isMobile ? "calc(100% - 64px)" : "380px", background: "#fff", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)', padding: "32px", border: "none",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center" }}>
                <Box sx={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                <Typography sx={{fontWeight:"600",color:"primary.light",lineHeight:"24px"}}>Verify Your Mobile Number</Typography>
                <CloseIcon onClick={()=>setOpenOtpModal(false) } sx={{cursor:"pointer"}} />
                </Box>
                {
                    verify=="mobile"?
               
                <Typography sx={{fontSize:"14px",lineHeight:"20px"}}>   {whatsapp ?"A verification code has been sent to your changed Whatsapp number":"A verification code has been sent to your changed Mobile no"} {maskMobileNo(mobile)}</Typography>
                :
                <Typography sx={{fontSize:"14px",lineHeight:"20px"}}>A verification code has been sent to your changed Email {maskEmail(email)}</Typography> }
                <MuiOtpInput gap={isMobile?.1:1} sx={{marginTop:"16px"}} value={otp} length={6}  onChange={(value)=>setOtp(value)}/>
               {/* {otpNotmatch  ?<Typography sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px"}} color="error">Invalid code. Please enter the valid code.</Typography>: !time==0&&<Typography sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px",textAlign:"start",width:"100%",}} color="error">Resend Code ({formatTime(time)} remaining)</Typography>} */}
                    <Box sx={{margin:"4px 0px",alignSelf:"flex-start"}}>
                    {otpNotmatch  ?<Typography sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px"}} color="error">Invalid code. Please enter the valid code.</Typography>: !time==0&&<Typography sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px",textAlign:"start",width:"100%",}} color="error">Resend Code ({formatTime(time)} remaining)</Typography>}

                    </Box>
                
               <Box sx={{ display:"flex", justifyContent:"space-between",width:"100%"}}>
                        {isloading ?<Button variant="contained" sx={{bgcolor:"primary.light",color:"primary.contrastText",height:"48px",maxWidth:"77px",width:"100%",textTransform: "none"}}><Typography sx={{fontWeight: 400, fontSize: "16px",lineHeight:"24px"}}>{<CircularProgress color="inherit" size={30}/>}</Typography></Button>
                        :<Button variant="contained" sx={{bgcolor:"primary.light",color:"primary.contrastText",height:"48px",maxWidth:"77px",width:"100%",textTransform: "none"}} onClick={handleComplete}><Typography sx={{fontWeight: 400, fontSize: "16px",lineHeight:"24px"}}>Submit</Typography></Button>}
                        <Button disabled={!resend || time!==0 ?true:false} sx={{bgcolor:"transparent",border:"none",outline:"none",textTransform:"none"}} onClick={(e)=>handleResendOtp(e)}><Typography sx={{fontWeight: 400, fontSize: "16px",lineHeight:"24px",}} color={resend&& time==0 ?"primary":"#BFC7D4"}>Resend OTP</Typography></Button>
            </Box>
               
            </Box>
            </Modal>
    )
}

export default Mobile_EmailVerify